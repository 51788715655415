.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 16px;
			color: var(--color-primary);
			font-weight: bold;
			text-transform: uppercase;

			&.big {
				font-size: 18px;

				@media (max-width:767px) {
					text-align: left !important;
				}

				&.text-cn-bl {
					@media (min-width:768px) {
						padding-left: 20%;
					}
				}
			}
		}

		.text-muted {
			color: var(--color-primary-light) !important;
		}

		.f-logo {
			background: #fff;
			padding: 10px;
			display: inline-block;
			width: 75%;

			.logo {
				width: 100%;
				height: auto;

			}
		}


		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.v-office-logo {
			text-align: right;
			margin-top: 35px;

			@media (max-width:767px) {
				text-align: left;
			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);
		font-size: var(--font-size-sm);
	}

}